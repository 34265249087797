import { ColorType } from "./types";
import {
  PURPLE,
  RED,
  ORANGE,
  BLUE,
  GREEN,
  YELLOW,
  GRAY,
  BROWN,
  PINK,
  TURQUOISE,
} from "./constant";

const INITIAL_PUZZLE_COLOR_1: ColorType[][] = [
  [PURPLE, PURPLE, ORANGE, ORANGE, ORANGE, BLUE, BLUE, BLUE],
  [PURPLE, ORANGE, ORANGE, ORANGE, ORANGE, ORANGE, BLUE, BLUE],
  [ORANGE, ORANGE, ORANGE, ORANGE, ORANGE, ORANGE, ORANGE, ORANGE],
  [ORANGE, GREEN, GRAY, GRAY, GRAY, GRAY, GREEN, ORANGE],
  [ORANGE, GREEN, GRAY, GRAY, RED, RED, GREEN, YELLOW],
  [ORANGE, GREEN, GRAY, GRAY, GRAY, GRAY, GREEN, YELLOW],
  [BROWN, GREEN, GRAY, GRAY, GRAY, GRAY, GREEN, YELLOW],
  [BROWN, GREEN, GREEN, GREEN, GREEN, GREEN, GREEN, YELLOW],
];

const INITIAL_PUZZLE_COLOR_2: ColorType[][] = [
  [RED, RED, RED, GRAY, GRAY, TURQUOISE, TURQUOISE, TURQUOISE],
  [RED, GRAY, GRAY, GRAY, GRAY, GRAY, GRAY, TURQUOISE],
  [RED, RED, GRAY, GRAY, GRAY, GRAY, TURQUOISE, TURQUOISE],
  [RED, GRAY, GRAY, GRAY, GRAY, GRAY, GRAY, PINK],
  [GREEN, BLUE, GRAY, GRAY, GRAY, GRAY, ORANGE, PINK],
  [GREEN, BLUE, BLUE, BLUE, ORANGE, ORANGE, ORANGE, PINK],
  [GREEN, BLUE, PURPLE, BLUE, ORANGE, PURPLE, ORANGE, PINK],
  [GREEN, GREEN, PURPLE, PURPLE, PURPLE, PURPLE, PINK, PINK],
];

const INITIAL_PUZZLE_COLOR_3: ColorType[][] = [
  [PURPLE, PURPLE, YELLOW, PURPLE, PURPLE, PURPLE, PURPLE, BLUE],
  [PURPLE, YELLOW, YELLOW, YELLOW, PURPLE, PURPLE, BLUE, BLUE],
  [PURPLE, PURPLE, YELLOW, PURPLE, PURPLE, GREEN, BLUE, GREEN],
  [PURPLE, PURPLE, PURPLE, PURPLE, GRAY, GREEN, GREEN, GREEN],
  [PURPLE, PURPLE, PURPLE, GRAY, GRAY, GRAY, GREEN, GREEN],
  [PURPLE, PURPLE, RED, GRAY, BROWN, GREEN, GREEN, GREEN],
  [PURPLE, RED, RED, BROWN, BROWN, GREEN, ORANGE, ORANGE],
  [RED, RED, BROWN, BROWN, ORANGE, ORANGE, ORANGE, ORANGE],
];

const INITIAL_PUZZLE_COLOR_4: ColorType[][] = [
  [PURPLE, PURPLE, PURPLE, PURPLE, ORANGE, ORANGE, ORANGE, ORANGE, ORANGE],
  [PURPLE, PURPLE, BLUE, PURPLE, ORANGE, PINK, PINK, ORANGE, ORANGE],
  [PURPLE, BLUE, BLUE, GRAY, ORANGE, PINK, ORANGE, ORANGE, ORANGE],
  [PURPLE, BLUE, GRAY, GRAY, ORANGE, PINK, ORANGE, ORANGE, ORANGE],
  [ORANGE, ORANGE, ORANGE, ORANGE, ORANGE, ORANGE, ORANGE, ORANGE, ORANGE],
  [ORANGE, ORANGE, ORANGE, ORANGE, ORANGE, GREEN, GREEN, GREEN, GREEN],
  [ORANGE, ORANGE, RED, ORANGE, ORANGE, GREEN, BROWN, BROWN, GREEN],
  [ORANGE, RED, RED, RED, ORANGE, GREEN, BROWN, YELLOW, GREEN],
  [ORANGE, ORANGE, RED, ORANGE, ORANGE, YELLOW, YELLOW, YELLOW, GREEN],
];

const INITIAL_PUZZLE_COLOR_5: ColorType[][] = [
  [PURPLE, PURPLE, PURPLE, PURPLE, PURPLE, PURPLE, PURPLE, PURPLE, PURPLE],
  [PURPLE, PURPLE, ORANGE, PURPLE, BLUE, BLUE, BLUE, PURPLE, PURPLE],
  [PURPLE, ORANGE, ORANGE, ORANGE, GREEN, BLUE, BLUE, BLUE, BLUE],
  [PURPLE, PURPLE, ORANGE, GREEN, GREEN, GREEN, GRAY, BLUE, BLUE],
  [PURPLE, PURPLE, PURPLE, PURPLE, GREEN, GRAY, GRAY, GRAY, BLUE],
  [PURPLE, PINK, PINK, PINK, TURQUOISE, BLUE, GRAY, BLUE, BLUE],
  [PURPLE, RED, RED, RED, TURQUOISE, BLUE, BLUE, BLUE, BLUE],
  [PURPLE, RED, RED, RED, TURQUOISE, YELLOW, YELLOW, YELLOW, BLUE],
  [PURPLE, PURPLE, RED, RED, RED, BLUE, BLUE, BLUE, BLUE],
];

// prettier-ignore
const INITIAL_PUZZLE_COLOR_6: ColorType[][] = [
  [ORANGE, ORANGE, ORANGE, ORANGE, ORANGE, ORANGE, ORANGE, BROWN, BROWN, BROWN],
  [ORANGE, ORANGE, RED, ORANGE, ORANGE, BLUE, ORANGE, ORANGE, BROWN, BROWN],
  [ORANGE, RED, RED, PURPLE, PURPLE, BLUE, BLUE, ORANGE, ORANGE, BROWN],
  [RED, RED, PURPLE, PURPLE, GRAY, GRAY, BLUE, BLUE, ORANGE, ORANGE],
  [TURQUOISE, TURQUOISE, PURPLE, GRAY, GRAY, ORANGE, ORANGE, GREEN, GREEN, ORANGE],
  [ORANGE, TURQUOISE, TURQUOISE, GRAY, ORANGE, ORANGE, GREEN, GREEN, ORANGE, ORANGE],
  [ORANGE, ORANGE, TURQUOISE, ORANGE, ORANGE, ORANGE, GREEN, ORANGE, ORANGE, ORANGE],
  [ORANGE, ORANGE, ORANGE, ORANGE, PINK, ORANGE, ORANGE, ORANGE, YELLOW, ORANGE],
  [ORANGE, ORANGE, ORANGE, PINK, PINK, ORANGE, ORANGE, YELLOW, YELLOW, ORANGE],
  [ORANGE, ORANGE, PINK, PINK, ORANGE, ORANGE, YELLOW, YELLOW, ORANGE, ORANGE],
]

export const INITIAL_PUZZLE_OPTIONS: ColorType[][][] = [
  INITIAL_PUZZLE_COLOR_1,
  INITIAL_PUZZLE_COLOR_2,
  INITIAL_PUZZLE_COLOR_3,
  INITIAL_PUZZLE_COLOR_4,
  INITIAL_PUZZLE_COLOR_5,
  INITIAL_PUZZLE_COLOR_6,
];

export const DEFAULT_SIZE = 8;

export function generateInitialBlankPuzzle({
  size,
}: {
  size: number;
}): ColorType[][] {
  return Array.from({ length: size }, () => Array(size).fill(null));
}
